<template>
  <div class="container-approaches">
    <div class="content-loading-approaches" @click.stop v-show=loadApproachesList>
      <Circle2 class="spinner-social-approaches"/>
    </div>
    <Menu />
    <div class="content-approaches">
      <SearchMenu :approaches="true" />
      <NoDataToShow v-if="showNoResults" :title="$t('approaches.no_results')"/>
      <div class="section-approaches" v-if="!loadApproachesList">
        <div class="content-status-approaches">
          <ApproachesStatus v-if="stats?.length" :dataStats="stats" :tooltipMsg="bouncesMsg" />
          <button class="button-resend-approaches" v-if="listOfPageWithItems[currentPage] && showButtonResend" v-title="$t('resend.label_resend')" @click="openModalResend = true">
            <p class="label-button-resend-approaches">{{ $t('resend.resend') }}</p>
            <i class="material-icons icon-resend-approaches">send</i>
          </button>
        </div>
        <div class="content-cards-approaches">
          <ApproachesCard v-for="item in listOfPageWithItems[currentPage]" :key="item.id" :dataCard="item" @open-details-sending="openDetails($event)" @delete-approach="approachDelete($event)" />
        </div>
        <div class="container-paginate-approaches-list" v-if="Object.keys(listOfPageWithItems).length > 0 && !loadApproachesList">
          <app-paginate :initValue="currentPage" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="10" :pageCount="dynamicPageCount" />
        </div>
      </div>
    </div>
    <ModalResend v-if="openModalResend" @close-modal-resend="closeModalResend()" />
    <SidebarSendings v-if="showDetails" :id="sendingId" @closeSendings="closeSidebar" :key='detailsKey'/>
    <app-modal
      v-model="modalToDelete"
      lightbox
      :title="$t('tickets.confirm_action')"
      close
      class="modal-default-primary">
      <div class="content-delete-approach">
        <app-heading level="h5">{{ $t("approaches.confirm_delete") }}</app-heading>
        <div class="buttons-delete-approach">
          <app-button class="btn-default-primary" @click="modalToDelete = false" :disabled="loadingDelete"> {{ $t('notifications.cancel') }} </app-button>
          <app-button class="btn-circle-accent" @click="actionToDelete()" :disabled="loadingDelete">{{$t('approaches.delete')}}</app-button>
        </div>
      </div>
    </app-modal>
  </div>
</template>

<script>
import { approachesService, userService } from '@/_services'
import { formatters } from '@/_helpers'
import { gsap } from 'gsap'
import ModalResend from '../components/resend/ModalResend.vue'
import Menu from '../components/Menu.vue'
import SearchMenu from '../components/dashboard/menu/SearchMenu.vue'
import SidebarSendings from '../components/SideBarConfig/SidebarSendings.vue'
import NoDataToShow from '../components/alerts/NoDataToShow.vue'
import Store from '@/store/'
import ApproachesStatus from '../components/approaches/ApproachesStatus.vue'
import ApproachesCard from '../components/approaches/ApproachesCard.vue'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'Approaches',
  components: {
    Menu,
    SearchMenu,
    ModalResend,
    NoDataToShow,
    ApproachesCard,
    SidebarSendings,
    ApproachesStatus,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      approachToDelete: '',
      loadingDelete: false,
      modalToDelete: false,
      loadApproachesList: true,
      openModalResend: false,
      openHistoryResend: false,
      rangeEnd: 19,
      isMobile: false,
      contentLength: undefined,
      listOfPageWithItems: {},
      dynamicPageCount: 0,
      currentPage: 1,
      stats: null,
      bouncesMsg: '',
      approaches: [],
      showNoResults: false,
      openUserProfile: false,
      showDetails: false,
      sendingId: '',
      detailsKey: 0
    }
  },
  beforeRouteEnter (to, from, next) {
    const user = Store.getters['account/getUser']
    if (!user.features.sendings) {
      return next({ name: 'YouCant' })
    }
    next(vm => {
      if (from.name !== null && from.name === 'Approaches') {
        const query = JSON.stringify({ $and: [{ archivedAt: { $exists: false } }] })
        vm.$store.commit('filters/resetAllFilters')
        vm.$router.push({ path: to.path, query: { q: query, sending: vm.$route.query.sending } }).catch(() => { })
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
    this.$root.$off('filter-approach-type')
    this.$root.$off('select-update')
    this.$root.$off('search-menu-is-done')
    this.$root.$off('load-favorite-filter-for-approaches')
  },
  created () {
    this.$store.commit('providers/UPDATE_PROVIDER', { key: 'sendingIdOnQuery', value: this.$route.query.sending })
    if (this.sendingFromNotification) {
      this.openDetails({ id: this.sendingFromNotification })
    }
  },
  async mounted () {
    if (!this.approaches.length) { this.updateUser() }

    this.$root.$on('select-update', () => {
      this.getApproachesData()
    })

    this.$root.$on('filter-approach-type', () => {
      this.setLoading()
      this.getApproachesData()
    })

    this.$nextTick(() => {
      this.$root.$on('filter-updated', () => {
        this.setLoading()
        this.getApproachesData()
      })
    })

    this.$root.$on('load-favorite-filter-for-approaches', () => {
      this.getApproachesData()
    })
  },
  computed: {
    account () { return this.$store.getters['account/getAccount'] },
    showButtonResend () { return this.account.features.allowResendSending && this.user.features.seedBatches },
    userSurveyLink () { return !!this.$store.getters['account/getUser'].features.sendingsSurveyLink },
    user () { return this.$store.getters['account/getUser'] },
    approachType () { return this.$store.getters['approaches/getSelectedType'] },
    sendingFromNotification () { return this.$store.getters['providers/getSendingOnQuery'] },
    roles () {
      let roles = [
        { type: 'user', label: 'user', value: false },
        { type: 'editor', label: 'editor', value: false },
        { type: 'admin', label: 'admin', value: false }
      ]
      if (this.user.type === 'superadmin') {
        roles.push({ type: 'superadmin', label: 'superadmin', value: false })
      } else if (this.user.type === 'user') {
        roles = []
      }
      return roles
    },
    lang () { return this.$store.getters['account/getLang'] }
  },
  methods: {
    approachDelete (value) {
      this.modalToDelete = true
      this.approachToDelete = value
    },
    async updateUser () {
      const result = await userService.getMe()
      this.$store.commit('account/setUser', result)
      await this.changePage(1)
    },
    setLoading (e) {
      if (!e) { this.loadApproachesList = true }

      const opacity = e ? 0 : 1
      const divLoading = document.querySelector('.content-loading-approaches')
      gsap.to(divLoading, {
        opacity,
        duration: 0.2,
        onComplete: () => {
          if (e) { this.loadApproachesList = false }
        }
      })
    },
    closeModalResend () {
      const modal = document.querySelector('.container-modal-resend')
      gsap.to(modal, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => { this.openModalResend = false }
      })
    },
    getApproachesData () {
      if (this.$route.name === 'Approaches') {
        this.approaches = []
        this.stats = []
        this.rangeEnd = 20
        this.contentLength = undefined
        this.listOfPageWithItems = {}
        this.dynamicPageCount = 1
        this.currentPage = 1
        this.$nextTick(async () => {
          this.changePage(1)
        })
      }
    },
    returnTypeIcon (type) {
      const iconOptions = [
        { type: 'link', icon: 'link' },
        { type: 'sms', icon: 'phone' },
        { type: 'email', icon: 'mail' },
        { type: 'widget', icon: 'integration_instructions' },
        { type: 'whatsapp', icon: 'call' }
      ]
      const findIcon = iconOptions.find(e => e.type === type)
      return findIcon.icon
    },
    returnStatusSend (e) {
      let returnStatus = ''
      const status = [
        { title: 'rejected', value: this.$t('approaches.rejected') },
        { title: 'bounced', value: this.$t('approaches.bounces') },
        { title: 'soft-bounced', value: this.$t('approaches.softBounces') },
        { title: 'deferred', value: this.$t('approaches.deferred') },
        { title: 'spam', value: this.$t('approaches.spam') }
      ].find(item => item.title === e)

      if (status) {
        returnStatus = status.value
      }

      return returnStatus
    },
    openDetails (item) {
      this.showDetails = true
      this.sendingId = item.id
      this.detailsKey++
    },
    async changePage (page) {
      const alreadyHasItems = this.listOfPageWithItems[page]
      if (!alreadyHasItems || alreadyHasItems.length <= 0) {
        this.setLoading()
        await this.loadMore(page)
      }
      this.currentPage = page
      window.scrollTo({ top: 200, behavior: 'smooth' })
    },
    async getApproaches (rangeEnd, currentPageChange = this.currentPage) {
      await new Promise(resolve => setTimeout(resolve, 500))

      let result = []
      let filterData = this.$route.query.q
      const selectedType = this.$store.getters['approaches/getSelectedType']

      if (typeof filterData !== 'object' && filterData) {
        filterData = JSON.parse(filterData)

        const find = window._.find(this.$store.getters['filters/getDashFilter'].query.$and, 'archivedAt')

        if (selectedType.type !== 'all' && !find) { filterData.$and.push(selectedType.toQuery) }

        filterData = JSON.stringify(filterData)
      } else {
        filterData = JSON.stringify(filterData)
      }

      filterData = formatters.formatDateByAddTimezone(filterData, 3, 'hours')
      if (this.sendingFromNotification) {
        filterData = formatters.insertSendingIdOnQuery(filterData, this.sendingFromNotification)
      }
      const formatRangeEnd = (this.contentLength !== undefined && rangeEnd - 1 > this.contentLength) ? this.contentLength : rangeEnd - 1

      const [response, stats] = await Promise.all([
        approachesService.getApproaches(filterData, formatRangeEnd, rangeEnd - 20),
        approachesService.getApproachesStats(filterData)
      ])

      const range = response?.range

      if (response?.data) { result = await response.data }

      if (!result.length || !stats) {
        return this.resetApproaches()
      }

      const splittedLength = range.split('/')
      this.dynamicPageCount = splittedLength.length > 0 && splittedLength[1] ? Math.ceil(splittedLength[1] / 20) : 1
      const formattedResult = this.formatApproachesAndStats(result, stats)
      this.listOfPageWithItems[currentPageChange] = formattedResult
      this.showNoResults = false
      this.setLoading(true)
      this.currentPage = currentPageChange
      this.contentLength = splittedLength[1] || 0
    },
    resetApproaches () {
      this.rangeEnd = 20
      this.contentLength = undefined
      this.listOfPageWithItems = {}
      this.dynamicPageCount = 1
      this.currentPage = 1
      this.setLoading(true)
      this.showNoResults = true
    },
    formatApproachesAndStats (approaches, stats) {
      const deliveries = (stats.sendings - stats.softBounces - stats.bounces - stats.spam)
      let deliveryRate = (deliveries / stats.sendings) * 100
      let responseRate = (stats.responses / deliveries) * 100

      deliveryRate = Math.max(0, Math.min(deliveryRate, 100))
      responseRate = Math.max(0, Math.min(responseRate, 100))

      this.bouncesMsg = `${this.$t('approaches.bounces')}: ${stats.bounces}, ${this.$t('approaches.softBounces')}: ${stats.softBounces}, ${this.$t('approaches.spam')}: ${stats.spam}`
      this.stats = [
        { title: this.$t('approaches.sendings'), value: stats.sendings },
        { title: this.$t('approaches.deliveries'), value: deliveries, bouncesMessage: true },
        { title: this.$t('approaches.delivery_rate'), value: deliveryRate, formula: 'percent' },
        { title: this.$t('approaches.openings'), value: stats.openings },
        { title: this.$t('approaches.clicks'), value: stats.clicks },
        { title: this.$t('approaches.answers'), value: stats.responses },
        { title: this.$t('approaches.response_rate'), value: responseRate, formula: 'percent' }
      ]
      const listOfApproaches = []

      approaches.map(item => {
        const approachToPush = {
          archivedAt: item.archivedAt || '',
          name: item.contact && item.contact.name !== '' ? item.contact.name : this.$t('dashboard.no_name'),
          survey: item.survey ? item.survey.title : '',
          state: item.lastEvent?.state ? this.returnStatusSend(item.lastEvent.state) : '',
          openedAt: item.openedAt ? formatters.formatDateByMongoTimeZone(item.openedAt, this.lang) : this.$t('approaches.not_opened'),
          respondedAt: item.respondedAt ? formatters.formatDateByMongoTimeZone(item.respondedAt, this.lang) : this.$t('approaches.not_answered'),
          send: item.createdAt ? formatters.formatDateByMongoTimeZone(item.createdAt, this.lang) : '',
          surveyId: item.survey ? item.survey._id : '',
          id: item._id,
          active: true,
          type: item.type
        }
        if (item.contact) {
          if (item.contact.phone !== undefined) {
            approachToPush.phone = item.contact.phone
          }
          if (item.contact.email !== undefined) {
            approachToPush.email = item.contact.email
          }
        } else {
          approachToPush.phone = this.$t('dashboard.no_phone')
          approachToPush.email = this.$t('dashboard.no_email')
        }
        listOfApproaches.push(approachToPush)
      })
      return listOfApproaches
    },
    async loadMore (currentPageChanged = this.currentPage) {
      this.rangeEnd = currentPageChanged * 20
      this.getApproaches(this.rangeEnd, currentPageChanged)
    },
    async actionToDelete () {
      this.loadingDelete = true
      this.modalToDelete = false
      this.setLoading()

      try {
        const findIndexApproach = this.listOfPageWithItems[this.currentPage].findIndex(ap => ap.id === this.approachToDelete)

        if (findIndexApproach < 0) throw new Error()

        await approachesService.deleteApproach(this.approachToDelete)

        this.getApproachesData()

        this.$store.commit('alerts/alert', {
          message: this.$t('sendings.deleted'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
        this.currentApproachToDelete = []
      } catch (error) {
        console.error('Error when deleting approach', error)
        this.$store.commit('alerts/alert', {
          message: this.$t('sendings.error_deleted'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } finally {
        this.loadingDelete = false
        this.modalToDelete = false
        this.approachToDelete = ''
      }
    },
    closeSidebar () {
      if (this.$route.query.sending) {
        this.$router.push({ path: this.$route.path, query: { q: this.$route.query.q } }).catch(() => { })
      }

      this.showDetails = false
      this.resetFocusCards()
    },
    resetFocusCards () {
      const allContainerCards = document.querySelectorAll('.container-approaches-card')

      allContainerCards.forEach(container => {
        gsap.to(container, {
          opacity: 1,
          borderColor: '#e1e1e1',
          duration: 0.3
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content-loading-approaches {
  z-index: 99;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #EFF3F840;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-social-approaches {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}
.content-delete-approach {
  margin-top: 1.5vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  .buttons-delete-approach {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    button {
      margin: 0;
    }
  }
}

.container-approaches {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content-approaches {
    flex: 1;
    padding: 1vw 3.5vw;
    .section-approaches {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      .content-status-approaches {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button-resend-approaches {
          color: var(--default-text-color);
          background: var(--primary-color);
          padding: 0.4vw;
          border-radius: 0.5rem;
          box-shadow: 0 2px 7px 0 #30303029;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5vw;
          .label-button-resend-approaches {
            font-size: 0.75vw;
          }
          .icon-resend-approaches {
            font-size: 1.1vw;
          }
        }
      }
      .content-cards-approaches {
        display: flex;
        flex-direction: column;
      }
      .container-paginate-approaches-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .pagination {
          transform: scale(0.8)
        }
      }
    }
  }
}
</style>
